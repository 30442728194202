﻿.message_box {
    -webkit-box-shadow: 3px 7px 7px 2px rgba(0,0,0,0.5);
    -moz-box-shadow: 3px 7px 7px 2px rgba(0,0,0,0.5);
    box-shadow: 3px 7px 7px 2px rgba(0,0,0,0.5);
    background-color: #fff;
    height: 80px;
    width: 350px;
    display: none;
    position: fixed;
    top: 80px;
    right: 30px;
    color: #000;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 1000;

    .fa, .fas {
        color: #fff;
        border-radius: 50%;
    }

    .fa-check {
        background: #6be542;
        padding: 5px 6px;
    }

    .fa-times {
        background: #ff1744;
        padding: 5px 7px;
    }

    .close_button {
        position: absolute;
        right: 15px;
        background: none;
        color: #000;
        font-size: 20px;
        cursor: pointer;
    }
}
