﻿.multiselect-container {
    .dropdown_button {
        background: #efefef;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
    }

    .select_box {
        display: none;
        position: absolute;
        top: 32px;
        width: 170px;
        z-index: 1000;
        padding: 10px 0;
        color: black;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        border-radius: 0.25rem;
        background: white;

        &.show {
            display: block;
        }

        .custom-checkbox {
        }

        .custom-control-label {
            justify-content: left;
            padding-left: 22px;
            margin-left: 0;

            &:before {
                top: 5px;
                left: 1px;
            }

            &:after {
                top: 5px;
                left: 1px;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .form-check {
            justify-content: left;
            padding: 2px 10px;
        }
    }
}

.multiselect_title {
    top: 2px;
    position: relative;
}

.multiselect-selected-text {
    color: white !important;
}
