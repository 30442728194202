﻿.lds-dual-ring {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 10px;

    &:after {
        content: " ";
        display: block;
        width: 25px;
        height: 25px;
        margin: 1px;
        border-radius: 50%;
        border: 3px solid #000;
        border-color: #000 transparent #000 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

