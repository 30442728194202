﻿.modal {
    &.wide {
        .modal-dialog {
            max-width: 70%;
        }
    }
}


.nmodal {


    display: block;

    overflow-x: hidden;
    overflow-y: auto;

    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;


    z-index: 1050;



    .nmodal-backdrop
    {
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0.6;
        z-index: 1040;
        width: 100vw;
        height: 100vh;
        background-color: #000;
    }

    .nmodal-window
    {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        
        position: fixed;
        position: sticky;
        top: 50px;
        left: 0;
        z-index: 1050;

        width: 50%;
        margin: 0 auto;

        &.wide {
            width: 75%;
        }

        .nmodal-content
        {
            border-radius: 15px;
            border: none;

            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            outline: 0;

            .nmodal-header
            {
                background: #69f;
                padding: 10px 25px;
                cursor: move;
                border-bottom: none;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }

            .nmodal-body
            {
                padding: 15px 25px;
                line-height: 1.5;
                color: #696c89;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            
                position: relative;
                flex: 1 1 auto;
            }
        }
    }
}