﻿.taginput {
    .taginput-container {
        border: 1px solid #eee;
        padding: 5px;

        .selected-tag {
            padding: 5px;
            background: #d3d3d3;
            border-radius: 5px;
            margin-right: 5px;
            flex-shrink: 0;
            margin-bottom: 5px;

            i {
                font-size: 13px;
                position: relative;
                margin-left: 5px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .addtag-container {
            width: 100%;

            .addtag {
                border: none !important;
                padding: 5px;
                width: 100%;

                &:focus {
                    outline: none !important;
                }
            }
        }
    }

    .searchresult-container {
        border: 1px solid #eee;

        .searchresult-item {
            &.active, &:hover {
                background: #eee;
            }
        }
    }
}

.autocomplete {
    .searchresult-container {
        border: 1px solid #eee;

        .searchresult-item {
            &.active, &:hover {
                background: #eee;
            }
        }
    }
}
